import { Controller } from "@hotwired/stimulus"

export default class Imdb extends Controller {
  static values = {
    id: String,
    rating: Number
  }

  static classes = ["noRating"]
  static targets = ["rating"]

  static callback(response) {
    const resource = response.resource
    if (resource) {
      const id = resource.id.split("/")[2]
      document.querySelectorAll(`[data-imdb-id-value=${id}]`).forEach(element => {
        element.dataset.imdbRatingValue = resource.rating
      })
    }
  }

  connect() {
    this.load()
  }

  ratingValueChanged() {
    if (this.hasRatingValue) {
      this.ratingTarget.textContent = this.formattedRatingValue
      this.element.classList.remove(this.noRatingClass)
    }
  }

  load() {
    const script = document.createElement('script');
    script.src = this.url
    document.head.appendChild(script)
  }

  get url() {
    return `https://p.media-imdb.com/static-content/documents/v1/title/${this.idValue}/ratings%3Fjsonp=imdb.rating.run:imdb.api.title.ratings/data.json`
  }

  get formattedRatingValue() {
    return new Intl.NumberFormat('pl-PL', { minimumFractionDigits: 1 }).format((this.ratingValue / 1.0).toFixed(1))
  }
}

window.imdb = { rating: { run: Imdb.callback } }
