// Copyright Grzegorz Derebecki Xan.pl 2011
const $ = jQuery;

class Lightbox2 {
  static initClass() {
    this.prototype.pageSelector = "#container";
    this.prototype.lightboxSelector = "#lightbox2";
    this.prototype.containerSelector = "#lightbox2 .container";
    this.prototype.imageSelector = "#lightbox2 .container .image img";
    this.prototype.navigationSelector = "#lightbox2 > .navigation";
    this.prototype.scrollableSelector = "#lightbox2 > .navigation .scrollable";
    this.prototype.spinnerTimeoutId = null;
    this.prototype.spinnerDelay = 50;
    this.prototype.emptyImage = "/px.gif";
    this.prototype.smallScreenWidth = 969;
    this.prototype.jsonFormatter = "Lightbox2JsonFormatter";
    this.prototype.htmlFormatter = "Lightbox2HtmlFormatter";
    this.prototype.initialized = false;
  }


  constructor() {
    this.onSwipe = this.onSwipe.bind(this);
    this.onSwipeLeft = this.onSwipeLeft.bind(this);
    this.onSwipeRight = this.onSwipeRight.bind(this);
    this.loadNavigationImagesFromHtml = this.loadNavigationImagesFromHtml.bind(this);
    this.loadNavigationImagesFromJSON = this.loadNavigationImagesFromJSON.bind(this);
    this.resize = this.resize.bind(this);
    this.observeImages();
  }

  initializeLightbox() {
    this.appendTemplate();
    this.addObservers();
    this.initializeScrollble();
    return this.initialized = true;
  }

  scrollable() {
    return $(this.scrollableSelector).data("scrollable");
  }

  addObservers() {
    this.observeCurrentImage();
    this.observeNavigation();
    this.observeClose();
    this.observeKeyboard();
    this.observeSwipe();
    return this.observeResize();
  }

  observeResize() {
    return $(window).resize(this.resize);
  }


  observeCurrentImage() {
    $(this.containerSelector + " .next").on("click", () => {
      this.clickNextImage();
      return false;
    });

    return $(this.containerSelector + " .previous").on("click", () => {
      this.clickPreviousImage();
      return false;
    });
  }


  observeImages() {
    return $(document).on("click", "a[data-lightbox]", event => {
      event.preventDefault();
      return this.show(event.currentTarget);
    });
  }


  observeNavigation() {
    $(this.navigationSelector).on("click.lightbox2", ".items a", event => {
      this.onNavigationImageClick(event.currentTarget);
      return false;
    });

    $(this.navigationSelector + " .previous").on("click.lightbox2", event => {
      this.moveToPreviousNavigationPage();
      return false;
    });

    $(this.navigationSelector + " .next").on("click.lightbox2", event => {
      this.moveToNextNavigationPage();
      return false;
    });

    $(this.navigationSelector).on("currentImageChange.lightbox2", (event, imageLink) => {
      return this.scrollNavigationToImage(imageLink);
    });

    return $(this.navigationSelector).on("loadImages.lightbox2", (event, imageLink) => {
      return this.finishUpdateNavigationAfterLoadImages(imageLink);
    });
  }


  observeSwipe() {
    return $(this.containerSelector).swipe({
      triggerOnTouchEnd: true,
      swipeStatus: this.onSwipe,
      allowPageScroll: "vertical",
      threshold: 75
    });
  }
  observeKeyboard() {
    return $(document).bind("keydown.lightbox2", event => {
      if (this.isVisible()) { return this.takeActionAfterKeyPress(event); }
    });
  }


  observeClose() {
    return $(this.lightboxSelector + " .close").on("click", () => {
      this.hide();
      return false;
    });
  }


  takeActionAfterKeyPress(event) {
    const {
      keyCode
    } = event;

    // left arrow or backspace -> previous image
    if ((keyCode === 37) || (keyCode === 8)) {
      this.clickPreviousImage();
      event.preventDefault();
    }

    // right arrow, space or enter -> next image
    if ((keyCode === 39) || (keyCode === 32) || (keyCode === 13)) {
      this.clickNextImage();
      event.preventDefault();
    }

    // escape -> close lightbox
    if (keyCode === 27) {
      this.hide();
      return event.preventDefault();
    }
  }

  onSwipe(event, phase, direction, distance) {
    if ((phase === 'move') && ((direction === 'left') || (direction === 'right'))) {
      const duration = 0;
      if (direction === 'left') {
        return this.swipeImage(distance, duration);
      } else if (direction === 'right') {
        return this.swipeImage(-distance, duration);
      }
    } else if (phase === 'cancel') {
      return this.swipeImage(0, 500);
    } else if (phase === 'end') {
      if (direction === 'right') {
        return this.clickPreviousImage();
      } else if (direction === 'left') {
        return this.clickNextImage();
      }
    }
  }

  swipeImage(distance, duration) {
    const img = $(this.imageSelector);
    img.css("transition-duration", (duration / 1000).toFixed(1) + "s");
    const value = (distance < 0 ? "" : "-") + Math.abs(distance).toString();
    return img.css("transform", "translate(" + value + "px,0)");
  }

  onSwipeLeft() {
    return this.clickPreviousImage();
  }

  onSwipeRight() {
    return this.clickNextImage();
  }

  clickPreviousImage() {
    let prev = $(this.navigationSelector + " .items .current").prev();
    if (prev.length === 0) { prev = $(this.navigationSelector + " .items").children().last(); }
    return prev.click();
  }

  clickNextImage() {
    let next = $(this.navigationSelector + " .items .current").next();
    if (next.length === 0) { next = $(this.navigationSelector + " .items").children().first(); }
    return next.click();
  }


  loadImage(imageLink, animation) {
    this.showSpinner();
    const $newImage = $(new Image());

    $newImage.on("load", event => {
      const image = event.currentTarget;
      $(this.imageSelector).replaceWith(image);
      this.setImageDescription(unescape($(image).data("description")));
      this.resize();
      return this.hideSpinner();
    });

    // description
    const description = $(imageLink).attr("data-description") || "";

    // update image, and description
    $newImage.attr("data-description", description);
    return $newImage.attr("src", $(imageLink).attr("href"));
  }

  cacheNextImage(imageLink) {
    return $(new Image()).attr("src", $(imageLink).next().attr("href"));
  }

  cachePreviousImage(imageLink) {
    return $(new Image()).attr("src", $(imageLink).prev().attr("href"));
  }

  updateNavigation(imageLink) {
    this.hideNavigation();
    this.clearNavigation();
    return this.loadNavigationImages(imageLink);
  }

  navigationShouldBeVisible() {
    return (this.scrollable().getSize() > 1) && ($(this.containerSelector).width() > this.smallScreenWidth);
  }

  finishUpdateNavigationAfterLoadImages(imageLink) {
    if (this.navigationShouldBeVisible()) { this.showNavigation(); }
    return this.findImageInNavigation(imageLink).click();
  }

  loadNavigationImages(imageLink) {
    if ($(imageLink).data("type") === "json") {
      return this.loadNavigationImagesFromJSON(imageLink);
    } else {
      return this.loadNavigationImagesFromHtml(imageLink);
    }
  }

  loadNavigationImagesFromHtml(imageLink) {
    const $images = $("a[data-lightbox=\"" + $(imageLink).data("lightbox") + "\"]");
    const formatterClass = this.formatterClass(imageLink, "Lightbox2HtmlFormatter");
    $images.each((index, item) => {
      const formatter = new (window[formatterClass])(index, item);
      return this.addNavigationImage(formatter.thumbnailUrl(), formatter.imageUrl(), formatter.formattedDescription(), index);
    });


    // notify that we finished loading images
    return $(this.navigationSelector).trigger($.Event("loadImages"), [imageLink, "html"]);
  }

  loadNavigationImagesFromJSON(imageLink) {
    const formatterClass = this.formatterClass(imageLink, "Lightbox2JsonFormatter");
    return $.getJSON($(imageLink).data("json"), images => {
      $.each(images, (index, item) => {
        const formatter = new (window[formatterClass])(index, item);
        return this.addNavigationImage(formatter.thumbnailUrl(), formatter.imageUrl(), formatter.formattedDescription(), index);
      });

      return $(this.navigationSelector).trigger($.Event("loadImages"), [imageLink, "json"]);
  });
  }


  formatterClass(imageLink, defaultClass) {
    return $(imageLink).data("formatter") || defaultClass;
  }

  onNavigationImageClick(sender) {

    this.setCurrentNavigationImage(sender);
    this.loadImage(sender);
    this.cacheNextImage(sender);
    return this.cachePreviousImage(sender);
  }


  moveToPreviousNavigationPage() {
    let offset = this.getNavigationPageSize();

    // if too big, limit to max move offset
    if (offset > this.scrollable().getIndex()) { offset = this.scrollable().getIndex(); }
    return this.scrollable().move(-offset);
  }

  moveToNextNavigationPage() {
    return this.scrollable().move(this.getNavigationPageSize());
  }


  // we should not cache this function becouse anyone can resize browser
  getNavigationPageSize() {
    const pageWidth = $(this.scrollableSelector).width();
    let pageSize = 1;
    this.scrollable().getItems().each(function(index, item) {
      const itemPosition = $(item).position().left + $(item).width();
      if (itemPosition > pageWidth) { return false; }
      return pageSize = index + 1;
    });

    return pageSize;
  }


  // on first call sender (imageLink) not becomes from navigation, we need find them in navigation
  findImageInNavigation(imageLink) {
    return $(this.navigationSelector + " .items a[href='" + $(imageLink).attr("href") + "']").first();
  }

  setCurrentNavigationImage(imageLink) {
    $(this.navigationSelector + " .items .current").removeClass("current");
    $(imageLink).addClass("current");

    // notify that current navigation image changed
    return $(this.navigationSelector).trigger($.Event("currentImageChange"), [imageLink]);
  }

  scrollNavigationToImage(imageLink) {
    const pageSize = this.getNavigationPageSize();
    const imageIndex = $(imageLink).data("index");
    const firstImageOnCurrentPageIndex = this.scrollable().getIndex();
    const firstImageIndex = Math.floor(imageIndex / pageSize) * pageSize;
    if (firstImageOnCurrentPageIndex !== firstImageIndex) { return this.scrollable().seekTo(firstImageIndex); }
  }

  addNavigationImage(thumbnailUrl, imageUrl, description, index) {
    const template = this.navigationImageTemplate(thumbnailUrl, imageUrl, description, index);
    return $(template).appendTo(this.navigationSelector + " .items");
  }

  navigationImageTemplate(thumbnailUrl, imageUrl, description, index) {
    return `\
<a href="${imageUrl}" data-index="${index}" data-description="${escape(description)}">
  <img src="${thumbnailUrl}" loading="lazy" class="img-fluid">
</a>\
`;
  }

  hideNavigation() {
    return $(this.lightboxSelector).addClass("navigation_hidden");
  }

  showNavigation() {
    return $(this.lightboxSelector).removeClass("navigation_hidden");
  }

  navigationIsVisible() {
    return !$(this.lightboxSelector).hasClass("navigation_hidden");
  }

  clearNavigation() {
    this.scrollable().begin(0);
    return $(this.navigationSelector + " .items").html("");
  }

  clearImage() {
    return $(this.imageSelector).replaceWith($("<img />").attr("src", this.emptyImage));
  }

  hide() {
    $(this.lightboxSelector).hide();
    return $(this.pageSelector).show();
  }

  show(eventSender) {
    if (!this.initialized) { this.initializeLightbox(); }
    $(this.lightboxSelector).show();
    $(this.pageSelector).hide();

    this.clearImage();
    this.clearImageDescription();
    return this.updateNavigation(eventSender);
  }


  showSpinner() {
    // show spinner after spinnerDelay
    // this will prevent showing spinner on arleady loaded images
    return this.spinnerTimeoutId = setTimeout(() => {
      return this.spinner().show();
    }
    , this.spinnerDelay);
  }

  hideSpinner() {
    clearTimeout(this.spinnerTimeoutId);
    return this.spinner().hide();
  }

  spinner() {
    return $(this.containerSelector + " .lightbox2-spinner");
  }

  setImageDescription(description) {
    const $description = $(this.containerSelector + " .description");
    $description.html(description);
    if ($description.is(":empty")) { return $description.hide(); } else { return $description.show(); }
  }

  clearImageDescription() {
    return this.setImageDescription("");
  }

  isVisible() {
    return $(this.lightboxSelector).is(":visible");
  }

  initializeScrollble() {
    return $(this.scrollableSelector).scrollable({
      next: null,
      prev: null,
      keyboard: false
    });
  }

  resize() {
    const $image = $(this.imageSelector);
    const $screen = $(this.containerSelector);
    const navigationHeight = (this.navigationIsVisible() ? $(this.navigationSelector).outerHeight() : 0);
    const realScreenHeight = $screen.height() - navigationHeight;
    const imageRatio = $image.width() / $image.height();
    const screenRatio = $screen.width() / realScreenHeight;
    if (imageRatio > screenRatio) {
      $image.width($screen.width());
      return $image.height(Math.floor($screen.width() / imageRatio));
    } else {
      $image.width(Math.floor(realScreenHeight * imageRatio));
      return $image.height(realScreenHeight);
    }
  }

  appendTemplate() {
    return $("body").append(this.template);
  }

  get template() {
    return `
      <div id="lightbox2" style="display: none;">
        <div class="overlay"></div>
        <div class="container">
          <div class="table_wraper">
            <span class="ie_fix"></span>
            <em class="cell_wraper">
              <div class="content">
                <div class="lightbox2-spinner">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 100 101" fill="none" class="inline h-12 w-12 animate-spin fill-orange-400 text-gray-200">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                  </svg>
                </div>
                <div class="image">
                   <img src="/px.gif" alt="">
                </div>
                <div class="close"><a href="#">
                  <svg viewBox="0 0 320 512" class="icon size-10" xmlns="http://www.w3.org/2000/svg"><path d="m193.94 256 102.56-102.56 21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0l-123.71 123.71-123.71-123.72c-3.12-3.12-8.19-3.12-11.31 0l-22.64 22.63c-3.12 3.12-3.12 8.19 0 11.31l123.72 123.72-123.72 123.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0l123.72-123.71 102.56 102.56 21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31z"/></svg>
                </a></div>
                <div class="description"></div>
                <div class="navigation">
                  <a href="#" class="previous">
                    <i class="button">
                      <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" class="icon size-10"><path d="m349.5 475.5-211.1-211c-4.7-4.7-4.7-12.3 0-17l211.1-211c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17l-195.5 195.4 195.5 195.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.6-12.3 4.6-17-.1zm-111 0 7.1-7.1c4.7-4.7 4.7-12.3 0-17l-195.5-195.4 195.4-195.5c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0l-211.1 211c-4.7 4.7-4.7 12.3 0 17l211.1 211c4.8 4.8 12.4 4.8 17.1.1z"/></svg>
                    </i>
                  </a>
                  <a href="#" class="next">
                    <i class="button">
                      <svg class="icon size-10" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="m34.5 36.5 211.1 211c4.7 4.7 4.7 12.3 0 17l-211.1 211c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l195.5-195.4-195.4-195.5c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.6 12.2-4.6 16.9.1zm111 0-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l195.5 195.4-195.4 195.5c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l211.1-211c4.7-4.7 4.7-12.3 0-17l-211.1-211c-4.8-4.8-12.4-4.8-17.1-.1z"/></svg>
                    </i>
                  </a>
                </div>
              </div>
            </em>
          </div>
        </div>
        <div class="navigation">
          <a href="" class="previous">
            <i class="button">
              <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" class="icon size-6"><path d="m349.5 475.5-211.1-211c-4.7-4.7-4.7-12.3 0-17l211.1-211c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17l-195.5 195.4 195.5 195.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.6-12.3 4.6-17-.1zm-111 0 7.1-7.1c4.7-4.7 4.7-12.3 0-17l-195.5-195.4 195.4-195.5c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0l-211.1 211c-4.7 4.7-4.7 12.3 0 17l211.1 211c4.8 4.8 12.4 4.8 17.1.1z"/></svg>
            </i>
          </a>
          <div class="scrollable">
            <div class="items"></div>
          </div>
          <a href="" class="next">
            <i class="button">
              <svg class="icon size-6" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="m34.5 36.5 211.1 211c4.7 4.7 4.7 12.3 0 17l-211.1 211c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l195.5-195.4-195.4-195.5c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.6 12.2-4.6 16.9.1zm111 0-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l195.5 195.4-195.4 195.5c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l211.1-211c4.7-4.7 4.7-12.3 0-17l-211.1-211c-4.8-4.8-12.4-4.8-17.1-.1z"/></svg>
            </i>
          </a>
        </div>
      </div>
    `;

  }


}
Lightbox2.initClass();

$(() => window.lightBox2 = new Lightbox2);
