import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items"]

  previous() {
    this.itemsTarget.scrollLeft -= this.itemsTarget.offsetWidth

  }

  next() {
    this.itemsTarget.scrollLeft += this.itemsTarget.offsetWidth
  }
}
